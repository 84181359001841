import React from 'react'
import './textBody.scss'

function TextBody({ children, body }) {
  return (
    <div className="textbody">
      {children}
      <div dangerouslySetInnerHTML={{ __html: body }}></div>    
    </div>
  )
}
export default TextBody
