// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-page-container-js": () => import("./../../../src/templates/blogPageContainer.js" /* webpackChunkName: "component---src-templates-blog-page-container-js" */),
  "component---src-templates-category-page-container-js": () => import("./../../../src/templates/categoryPageContainer.js" /* webpackChunkName: "component---src-templates-category-page-container-js" */),
  "component---src-templates-landing-page-container-js": () => import("./../../../src/templates/landingPageContainer.js" /* webpackChunkName: "component---src-templates-landing-page-container-js" */),
  "component---src-templates-newslist-page-container-js": () => import("./../../../src/templates/newslistPageContainer.js" /* webpackChunkName: "component---src-templates-newslist-page-container-js" */),
  "component---src-templates-page-container-js": () => import("./../../../src/templates/pageContainer.js" /* webpackChunkName: "component---src-templates-page-container-js" */),
  "component---src-templates-startpage-container-js": () => import("./../../../src/templates/startpageContainer.js" /* webpackChunkName: "component---src-templates-startpage-container-js" */)
}

