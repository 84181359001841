import './removeOutlines.scss';
if (typeof document !== 'undefined') {;
// outline.js
// based on http://www.paciellogroup.com/blog/2012/04/how-to-remove-css-outlines-in-an-accessible-manner/
(function (d) {
		

    var style_element = d.createElement('STYLE'),
	    dom_events = 'addEventListener' in d,
	    add_event_listener = function (type, callback) {
	        // Basic cross-browser event handling
	        if (dom_events) {
	            d.addEventListener(type, callback);
	        } else {
	            d.attachEvent('on' + type, callback);
	        }
	    },
	    set_css = function (css_text) {
			
			d.body.classList.add('non-visible-focus');

	        // Handle setting of <style> element contents in IE8
	        !!style_element.styleSheet ? style_element.styleSheet.cssText = css_text : style_element.innerHTML = css_text;
		},
		remove_css = function() {
			d.body.classList.remove('non-visible-focus');
		}
    ;

    d.getElementsByTagName('HEAD')[0].appendChild(style_element);

    // Using mousedown instead of mouseover, so that previously focused elements don't lose focus ring on mouse move
    add_event_listener('mousedown', function () {
		set_css();
        // set_css(':focus:not([type="text"]):not([type="search"]){outline:0; box-shadow:none !important;}::-moz-focus-inner{border:0;}');
    });

    add_event_listener('keydown', function () {
		remove_css();
        // set_css('');
    });

})(document);
}