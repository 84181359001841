import React from 'react'
import { Link } from 'gatsby'
import { TiArrowRight } from 'react-icons/ti'
import './button.scss'

function ButtonLink({
  href,
  children,
  modifier,
  hideDefaultIcon,
  onClick,
  scroll = true,
}) {
  const className = `button button--icon button--with-margin`
  const classNameWithModifier = modifier
    ? `${className} button--${modifier}`
    : className

  if (!href.includes('http') && href.charAt(0) !== '#') {
    return (
      <Link
        state={{ scroll: scroll }}
        to={href}
        className={classNameWithModifier}
        onClick={e => {
          if (onClick) {
            e.preventDefault()
            onClick()
          }
        }}
      >
        {children}
        {!hideDefaultIcon && <TiArrowRight />}
      </Link>
    )
  } else {
    return (
      <a
        href={href}
        className={classNameWithModifier}
        onClick={e => {
          if (onClick) {
            e.preventDefault()
            onClick()
          }
        }}
      >
        {children}
        {!hideDefaultIcon && <TiArrowRight />}
      </a>
    )
  }
}

export default ButtonLink
