import React, {
  useLayoutEffect,
  useState,
  useCallback,
  useContext,
  useEffect
} from 'react'
import './cookies.scss'
import { Link } from 'gatsby'
import ContentWrapper from '../contentWrapper'
import { LayoutContext } from '../../layout/index.js'

function CookieInfo({ cookiesLink }) {
  const [hidingCookieWarning, setHidingCookieWarning] = useState(true)
  const [showCookieWarning, setShowCookieWarning] = useState(false)
  const {
    localization,
    setHasAnsweredConsent,
    hasAnsweredConsent
  } = useContext(LayoutContext)

  const showCookie = useCallback(() => {
    setHasAnsweredConsent(false)
    setShowCookieWarning(true)
    setTimeout(() => {
      setHidingCookieWarning(false)
    }, 100)
  }, [setShowCookieWarning, setHidingCookieWarning, setHasAnsweredConsent])
  const hideCookie = useCallback(() => {
    setHasAnsweredConsent(true)
    setHidingCookieWarning(true)
    setTimeout(() => {
      setShowCookieWarning(false)
    }, 100)
  }, [setShowCookieWarning, setHidingCookieWarning, setHasAnsweredConsent])

  const dismissCookieDialog = useCallback(
    consent => {
      if (consent) {
        window._paq.push(['setConsentGiven'])
      } else {
        window._paq.push(['forgetConsentGiven'])
      }
      setHasAnsweredConsent(true)
      hideCookie()
    },
    [hideCookie, setHasAnsweredConsent]
  )

  useEffect(() => {
    if (!hasAnsweredConsent) {
      showCookie()
    }
  }, [hasAnsweredConsent, showCookie])

  useLayoutEffect(() => {
    window._paq = window._paq || []
    if (window && !hasAnsweredConsent) {
      window._paq.push(['forgetConsentGiven'])
      showCookie()
    }
  }, [dismissCookieDialog, hasAnsweredConsent, showCookie])

  if (showCookieWarning === false) return null

  return (
    <div
      className={hidingCookieWarning ? 'cookies cookies--hidden' : 'cookies'}
    >
      <ContentWrapper modifier="wide">
        <div className="cookies__inner">
          <p>
            {localization.cookie}{' '}
            <Link to={cookiesLink}>{localization.cookie_link}</Link>.
          </p>
          <div className="cookies-buttons">
            <button
              className="button button--icon button--icon-l button--invert"
              onClick={() => {
                dismissCookieDialog(false)
              }}
            >
              {localization.cookie_button_disapprove}
            </button>
            <button
              className="button button--icon button--icon-l button--invert"
              onClick={() => {
                dismissCookieDialog(true)
              }}
            >
              {localization.cookie_button}
            </button>
          </div>
        </div>
      </ContentWrapper>
    </div>
  )
}

export default CookieInfo
