import React, { useContext } from 'react'
import './footer.scss'
import ContentWrapper from '../components/contentWrapper'
import '../styles/patterns/columns.scss'
import { LayoutContext } from '../layout'

function Footer({ col1, col2, col3 }) {
  const { localization, setHasAnsweredConsent } = useContext(LayoutContext)
  return (
    <footer className="footer">
      <ContentWrapper modifier="wide">
        <div className="footer__columns">
          <div>{col1}</div>
          <div>{col2}</div>
          <div>
            {col3}
            <p>
              <button
                className="footer__a"
                onClick={() => setHasAnsweredConsent(false)}
              >
                {localization.cookie_change}
              </button>
            </p>
          </div>
        </div>
      </ContentWrapper>
    </footer>
  )
}
export default Footer
