import React from 'react'
import { INLINES, BLOCKS } from '@contentful/rich-text-types'
import linkResolver from './linkResolver'
import TextContainer from '../src/components/textContainer/textContainer'
import { renderRichText } from './renderRichText'
import Accordion from '../src/components/accordion'
import Embed from '../src/components/embed'
import Documents from '../src/components/documents/documents'
import { getFluidGatsbyImage } from './getFluidGatsbyImage'
import Image from '../src/components/image/image'
import ButtonLink from '../src/components/buttons/buttonLink'
import Contacts from '../src/components/contacts/contacts'
import { Link } from 'gatsby'

export const getRichTextRendererOptions = lang => {
  return {
    renderText: text =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br key={i} />, text]),
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        if (!node.data.uri) return ''
        return (
          <a href={node.data.uri} target="_blank" rel="noreferrer">
            {children}
          </a>
        )
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        if (!node.data.target.fields) return ''

        const href = linkResolver({
          slug: node.data.target.fields.slug[lang],
          node_locale: lang,
          pageType: node.data.target.fields.pageType
            ? Object.values(node.data.target.fields.pageType)[0]
            : null
        })

        return <Link to={href}>{children}</Link>
      },
      [INLINES.ASSET_HYPERLINK]: (node, children) => {
        const fields = node.data.target.fields
        if (!Object.values(fields.file)[0]) return
        const file = fields.file[lang] || fields.file['sv-SE']
        if (!file) return
        return (
          <a target="_blank" rel="noreferrer" href={file.url}>
            {children}
          </a>
        )
      },
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const fields = node.data.target.fields
        if (!Object.values(fields.file)[0]) return
        return (
          <img
            src={Object.values(fields.file)[0].url}
            alt=""
            width={Object.values(fields.file)[0].details.image.width / 2}
          />
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        const contentType = node.data.target.sys.contentType.sys.id
        const fields = node.data.target.fields
        switch (contentType) {
          case 'contentBlock':
            const { title, body, hideTitle } = node.data.target.fields
            return (
              <TextContainer
                title={title[lang]}
                body={renderRichText(body[lang], lang)}
                hideTitle={hideTitle ? Object.values(hideTitle)[0] : false}
              />
            )

          case 'accordionBlock':
            const { contentBlocks } = node.data.target.fields

            const items = contentBlocks[lang] || contentBlocks['sv-SE']
            const accordionItems = items.map(({ fields }) => {
              return {
                title: fields.title[lang],
                body: renderRichText(fields.body[lang], lang)
              }
            })
            return <Accordion items={accordionItems} />

          case 'embedBlock':
            return (
              <Embed
                title={fields.title[lang]}
                embed={fields.embedCode[lang]}
              />
            )

          case 'relatedLinksBlock':
            const relatedLinkItems = fields.relatedAssets[lang].map(asset => {
              return {
                title: asset.fields.title[lang],
                url: asset.fields.file[lang].url
              }
            })
            return (
              <Documents items={relatedLinkItems} title={fields.title[lang]} />
            )

          case 'imageBlock':
            if (!Object.values(fields.image)[0]) return ''
            return (
              <Image
                image={getFluidGatsbyImage(
                  {
                    file: Object.values(fields.image)[0].fields.file[lang],
                    title: Object.values(fields.image)[0].fields.title[lang]
                  },
                  { width: 760 }
                )}
                imageText={fields.imageText ? fields.imageText[lang] : ''}
                altText={fields.altText ? fields.altText[lang] : ''}
                imagePosition={fields.imagePosition ? fields.imagePosition[lang] : ''}
                isCircular={fields.isCircular ? fields.isCircular[lang] : ''}
              />
            )

          case 'callToActionBlock':
            if (
              !fields.externalUrl[lang] &&
              !Object.values(fields.pageReference)[0]
            )
              return null
            const href = fields.externalUrl[lang]
              ? fields.externalUrl[lang]
              : linkResolver(Object.values(fields.pageReference)[0].fields)
            return (
              <ButtonLink
                openInNewWindow={fields.openInNewWindow}
                href={href}
                // scroll={
                //   fields.pageReference[0] &&
                //   fields.pageReference[0].pageType === 'Overlay page'
                // }
              >
                {fields.title[lang]}
              </ButtonLink>
            )

          case 'contactBlock':
            if(!fields.contacts || !fields.contacts[lang]) {
              return null
            }
            const contactItems = fields.contacts[lang].map(contactItem => {
              return {
                name: contactItem.fields.name
                  ? Object.values(contactItem.fields.name)[0]
                  : null,
                title: contactItem.fields.title[lang],
                phone: contactItem.fields.phone
                  ? Object.values(contactItem.fields.phone)[0]
                  : null,
                email: contactItem.fields.email
                  ? Object.values(contactItem.fields.email)[0]
                  : null,
                image: getFluidGatsbyImage(
                  contactItem.fields.image
                    ? Object.values(contactItem.fields.image)[0].fields
                    : null,
                  {
                    width: 248,
                    height: 248
                  }
                )
              }
            })
            return (
              <Contacts
                contacts={contactItems}
                title={fields.title[lang]}
                showTitle={true}
              />
            )
          default:
            return null
        }
      }
    }
  }
}
