import React from 'react'
import TextBody from '../textBody'
import './textContainer.scss'

function TextContainer({ title, body, hideTitle }) {
  return (
    <div className="container">
      {!hideTitle && <h2 className="container__heading heading-3">{title}</h2>}
      <TextBody>{body}</TextBody>
    </div>
  )
}

export default TextContainer
