import Logotype from '../components/logotype'
import Menu from '../components/menu'
import React from 'react'

import './header.scss'

function Header({ menu, tools, type }) {
  return (
    <header className="header">
      <Logotype type={type} />
      <Menu items={menu} additionalItems={tools} />
    </header>
  )
}
export default Header
