import React from 'react'
import { Layout } from './src/layout'

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>
}

export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  if (!location.state) {
    return true
  }
  if (location.state.scroll === false) {
    return false
  }
  if (location.state.scrollTo) {
    return location.state.scrollTo
  }
  return true
}

export const onRouteUpdate = ({ location }) => {
  if (typeof window._paq !== 'undefined') {
    window._paq.push(['setCustomUrl', location.pathname])
    window._paq.push(['trackPageView'])
  }
}
