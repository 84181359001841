import React from 'react'
import './video.scss'

function Embed({ embed, title }) {
  return (
    <div className="video">
      <div dangerouslySetInnerHTML={{ __html: embed }}></div>
    </div>
  )
}
export default Embed
