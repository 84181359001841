const localization = require('../config/localization')

var linkResolver = doc => {
  if (!doc) return null

  if(doc.__typename === 'ContentfulExternalPage') {
    return doc.url
  }

  let path = doc.slug

  if (doc.pageType) {
    if (
      doc.pageType.includes('News article') ||
      doc.pageType.includes('Press release')
    ) {
      path = `${localization[doc.node_locale || 'sv-SE'].newsSlug}/${path}`
    }
    if (doc.pageType.includes('Blog post')) {
      path = `${localization[doc.node_locale || 'sv-SE'].blog_slug}/${path}`
    }
    if (doc.pageType.includes('Overlay page')) {
      const parent = doc.parentPage.fields
        ? doc.parentPage.fields
        : doc.parentPage
      if (parent && parent.slug) {
        if (window.location.pathname.includes(parent.slug)) {
          return `#${path}`
        } else {
          path = `${parent.slug}/#${path}`
        }
      }
    }
  }
  if (doc.node_locale && doc.node_locale !== 'sv-SE') {
    path = `${doc.node_locale.slice(0, 2)}/${path}`
  }

  return '/' + path

  // //Is external url
  // if (doc.url) return doc.url

  // //Doc can be the full document or in some cases only the _meta part.
  // let meta = doc._meta ? doc._meta : doc
  // let path = meta.uid

  // if (meta.type === 'page') {
  //   const parent = doc.parent
  //   if (parent && parent._meta.uid) {
  //     path = `${parent._meta.uid}/#${path}`
  //   } else {
  //     //Can't get parent because of max query depth length. Limits links to other pages on the same landing page.
  //     return `#${path}`
  //   }
  // }

  // if (meta.tags) {
  //   if (meta.tags.includes('nyhet') || meta.tags.includes('pressmeddelande')) {
  //     path = `${localization[meta.lang || 'sv-se'].newsSlug}/${path}`
  //   }
  //   if (meta.tags.includes('kärnpunkten')) {
  //     path = `${localization[meta.lang || 'sv-se'].blog_slug}/${path}`
  //   }
  // }

  // if (meta.lang && meta.lang !== 'sv-se') {
  //   path = `${meta.lang.substring(meta.lang.indexOf('-'), 0)}/${path}`
  // }

  // return '/' + path
}

module.exports = linkResolver
