module.exports = {
  'sv-SE': {
    show_all: 'Gå till pressrummet',
    news: 'Nyheter och pressmeddelanden',
    newsSlug: 'nyheter-och-press',
    language: 'English',
    news_tag: 'Nyhet',
    press_tag: 'Pressmeddelande',
    published: 'Publicerad',
    error: 'Något gick fel.',
    subscriber_exists: ' finns redan som prenumerant.',
    subscribe_success:
      'Tack! Du prenumererar nu på OKGs nyhetsbrev och pressmeddelanden.',
    subscribe: 'Prenumerera på vårt nyhetsbrev',
    subscribe_text:
      'Ange din e-post för att få våra pressmeddelanden och nyheter skickade till dig.',
    email: 'E-postadress',
    subscribe_button: 'Prenumerera',
    show_all_news: 'Visa alla nyheter',
    blog: 'Kärnpunkten',
    blog_slug: 'karnpunkten',
    blog_introduction:
      'Kärnpunkten är OKGs egna personalmagasin på webben lorem ipsum dolor sit amet molestie turpis nibh vulputate viverra nulla nibh cursus.',
    download_original: 'Ladda ner original',
    close: 'Stäng',
    cookie:
      'Inga kakor används på denna sida utöver de som är nödvändiga för sidans funktion. Grundläggande analys av våra besökare utförs i syfte att kunna förbättra sidans innehåll. Detta görs utan att sätta kakor eller identifiera någon besökare. Du kan alltid ändra ditt val genom att klicka på länken "Inställningar för samtycke" längst ner på sidan.',
    cookie_button: 'Jag godkänner',
    cookie_button_disapprove: 'Jag avböjer',
    cookie_link: 'Läs mer om kakor (cookies)',
    cookie_change: 'Inställningar för samtycke',
    logo_alt: 'Oskarshamns kärnkraftverk - OKG (till startsidan)',
    show_blog: 'Gå till Kärnpunkten',
    production_lamps: 'Lampor',
    production_coffee: 'Koppar kaffe',
    production_phones: 'Mobiltelefoner',
    production_tv: 'TV-apparater',
    production_error: 'Vi kan tyvärr inte visa produktionsdata för tillfället.',
    millions: 'miljoner',
    metrics_header: 'Produktionssiffror för 2023',
    metrics_produced: 'producerad el',
    metrics_availability: 'anläggningstillgänglighet',
    metrics_usage: 'av Sveriges elanvändning'
  },
  'en-US': {
    show_all: 'Show all',
    news: 'News and Press Releases',
    newsSlug: 'press-room',
    language: 'Svenska',
    news_tag: 'News',
    press_tag: 'Press Release',
    published: 'Published',
    error: 'Something went wrong.',
    subscriber_exists: ' already exists.',
    subscribe_success:
      'Thank you! You are now a subscriber of our news letter.',
    subscribe: 'Subscribe to our news letter',
    subscribe_text: 'Enter your email to recieve updates from us.',
    email: 'E-mail address',
    subscribe_button: 'Subscribe',
    show_all_news: 'Show all news',
    blog: 'Kärnpunkten',
    blog_slug: 'karnpunkten',
    download_original: 'Download original',
    close: 'Close',
    cookie:
      'No cookies are used on this page other than those necessary for the page\'s function. Basic analysis of our visitors is carried out in order to improve the page\'s content. This is done without setting cookies or identifying any visitor. You can always change your choice by clicking on the "Consent Settings" link at the bottom of the page.',
    cookie_button: 'I approve',
    cookie_button_disapprove: 'I refuse',
    cookie_link: 'Read more about cookies',
    cookie_change: 'Consent settings',
    logo_alt: 'Oskarshamn nuclear power plant - OKG (to the startpage)',
    production_lamps: 'Lamps',
    production_coffee: 'Cups of coffee',
    production_phones: 'Phones',
    production_tv: 'TVs',
    production_error: 'We cannot show our production data at the moment',
    millions: 'millions',
    metrics_header: 'Production figures 2023',
    metrics_produced: 'produced electricity',
    metrics_availability: 'availability',
    metrics_usage: 'of Sweden’s electricity consumption'
  }
}
