import React from 'react'
import AccordionItem from './accordionItem'

function Accordion({ items, foo }) {
  const itemsComponents = items.map((item, i) => {
    return <AccordionItem key={i} heading={item.title} text={item.body} />
  })

  return <div className="accordion-group">{itemsComponents}</div>
}

export default Accordion
