import React from 'react'
import { Link } from 'gatsby'
import { LayoutContext } from '../../layout/index.js'
import logotype from './logotype.svg'
import './logotype.scss'

function Logotype({ type }) {

  const { localization, lang } = React.useContext(LayoutContext)

  return (
    <div className="logotype">
      <Link to={lang !== 'sv' ? `/${lang}` : '/' }>
        <img
          src={logotype}
          alt={localization.logo_alt}
          width="175"
          height="77"
        />
      </Link>
    </div>
  )
}
export default Logotype
