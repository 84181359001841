import '../global/index.js'
import '../styles/base.scss'
import '../styles/common.scss'
import { useStaticQuery, graphql } from 'gatsby'
import React, { useState } from 'react'
import Header from './header'
import { Helmet } from 'react-helmet'
import Footer from './footer'
import localization from '../../config/localization'
import { renderRichText } from '../../contentful/renderRichText'
import appleTouchIcon from '../../static/apple-touch-icon.png'
import favicon16x16 from '../../static/favicon-16x16.png'
import favicon32x32 from '../../static/favicon-32x32.png'
import Cookies from 'js-cookie'
import CookieInfo from '../components/cookies'
import linkResolver from '../../contentful/linkResolver'
import localizationFilter from '../../contentful/localizationFilter.js'
const LayoutContext = React.createContext({
  lang: '',
  bodyClass: '',
  setBodyClass: () => {},
  localization: {}
})

const Layout = ({ children, pageContext: { locale, type } }) => {
  const [bodyClass, setBodyClass] = useState('')

  const [hasAnsweredConsent, _setHasAnsweredConsent] = useState(
    Cookies.get('hasBeenAsked') === 'true'
  )

  /**
   * @description - This function sets the value of the cookie 'consentAsked' to the value of the parameter
   * as well as the internal state hasAnsweredConsent
   * @param {boolean} value - The value to set the cookie 'consentAsked' to it
   * @returns {void}
   *
   */
  const setHasAnsweredConsent = value => {
    _setHasAnsweredConsent(value)
    Cookies.set('hasBeenAsked', value, { expires: 7 })
  }

  const shortLang = locale ? locale.slice(0, 2) : 'sv'
  locale = locale ? locale : 'sv-SE'

  const result = useStaticQuery(frameworkQuery)

  const start = result.allContentfulStartpage.edges.find(
    edge => edge.node.node_locale === locale
  ).node

  const framework = result.allContentfulFrameworkSettings.edges.find(
    edge => edge.node.node_locale === locale
  ).node

  const langSlug = locale === 'sv-SE' ? '' : `/${shortLang}`

  const menuItems = start.childPages.filter(localizationFilter).map(node => {
    return {
      pageName: node.heading,
      link: linkResolver(node),
      id: node.slug
    }
  })

  const toolsItems = framework.toolsMenu
    .filter(localizationFilter)
    .map(node => {
      return {
        pageName: node.heading,
        link: linkResolver(node),
        id: node.slug
      }
    })

  const footerColumn1 = renderRichText(framework.footerColumn1.json, locale)
  const footerColumn2 = renderRichText(framework.footerColumn2.json, locale)
  const footerColumn3 = renderRichText(framework.footerColumn3.json, locale)

  const cookiesLink = linkResolver(framework.cookiesPage)

  return (
    <LayoutContext.Provider
      value={{
        locale: locale,
        lang: shortLang,
        langSlug: langSlug,
        localization: localization[locale],
        bodyClass: bodyClass,
        siteName: framework.siteName,
        siteUrl: framework.siteUrl,
        setBodyClass: setBodyClass,
        hasAnsweredConsent,
        setHasAnsweredConsent
      }}
    >
      <div className={`layout ${type} ${bodyClass}`}>
        <Helmet>
          <html lang={locale ? locale.slice(0, 2) : 'sv'} />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cloud.typography.com/6759616/7892012/css/fonts.css"
          />
          <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={appleTouchIcon}
          ></link>
          <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={favicon32x32}
          ></link>
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={favicon16x16}
          ></link>
          {/* <!-- Matomo --> */}
          <script type="text/javascript">
            {`
                var _mtm = window._mtm = window._mtm || [];
                _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
                (function() {
                  var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                  g.async=true; g.src='https://okg-matomo.azurewebsites.net/js/${process.env.GATSBY_MATOMO_CONTAINER}'; s.parentNode.insertBefore(g,s);
                })();
            `}
          </script>
          <noscript
            dangerouslySetInnerHTML={{
              __html: `
                  <p>
                    <img
                      referrerpolicy="no-referrer-when-downgrade"
                      src="//okg-matomo.azurewebsites.net/matomo.php?idsite=${process.env.GATSBY_MATOMO_ID}&amp;rec=1"
                      style="border:0;"
                      alt=""
                    />
                  </p>
                `
            }}
          />
          {/* <!-- End Matomo Code --> */}
        </Helmet>
        <CookieInfo cookiesLink={cookiesLink} />
        <Header menu={menuItems} tools={toolsItems} type={type} />
        <div className="layout__content">{children}</div>
        <Footer
          col1={footerColumn1}
          col2={footerColumn2}
          col3={footerColumn3}
        />
      </div>
    </LayoutContext.Provider>
  )
}

export { LayoutContext, Layout }

const frameworkQuery = graphql`
  query {
    allContentfulFrameworkSettings {
      edges {
        node {
          siteName
          siteUrl
          node_locale
          toolsMenu {
            ...ContentfulLink
          }
          footerColumn1 {
            json
          }
          footerColumn2 {
            json
          }
          footerColumn3 {
            json
          }
          cookiesPage {
            ...ContentfulLink
          }
        }
      }
    }
    allContentfulStartpage {
      edges {
        node {
          node_locale
          childPages {
            ...ContentfulLink
          }
        }
      }
    }
  }
`
