import React from 'react'
import { FiFileText } from 'react-icons/fi'
import '../../styles/patterns/linkList.scss'

function Documents({ items, title }) {
  const documents = items.map((item, i) => {
    return (
      <li key={i}>
        <a href={item.url} target="_blank" rel="noreferrer">
          <span className="icon-circular">
            <FiFileText />
          </span>
          {item.title}
        </a>
      </li>
    )
  })

  return (
    <div>
      {title && <h2 className="heading-3 margin-t-lg">{title}</h2>}
      <ul className="link-list">{documents}</ul>
    </div>
  )
}

export default Documents
