import React from 'react'
import Img from 'gatsby-image'
import './image.scss'

function Image({ image, imageText, altText, imagePosition, isCircular }) {
  let className = 'content-image'
  if(imagePosition) {
    className += ` content-image--${imagePosition.toLowerCase()}`
  }
  if(isCircular) {
    className += ` content-image--circle`
  }
  return (
    <React.Fragment>
      {image && (
        <div className={className}>
          <Img
            fluid={image}
            placeholderClassName="lazy"
            alt={altText}
          />
          {imageText && <p>{imageText}</p>}
        </div>
      )}
    </React.Fragment>
  )
}

export default Image
