import React from 'react'
import './contentWrapper.scss'

function ContentWrapper({ children, modifier }) {
  const className = modifier ? `content__wrapper ${modifier}` : 'content__wrapper'
  return (
    <div className={className}>
      <div className="content__wrapper__inner">{children}</div>
    </div>
  )
}
export default ContentWrapper
