import React from 'react'
import Img from 'gatsby-image'
import { FiSmartphone, FiMail } from 'react-icons/fi'
import '../../styles/patterns/container.scss'
import './contactCard.scss'

function ContactCard({ name, title, phone, email, image }) {
  return (
    <div className="contact">
      <div className="contact__body">
        {image && (
          <div className="contact__body__image">
            <Img
              fluid={image}
              placeholderClassName="lazy"
            />
          </div>
        )}
        <div className="contact__body__name">
          <strong>{name}</strong>, <span>{title}</span>
          <div className="contact__footer">
            {phone && (
              <div>
                <div className="icon-circular icon-circular--green">
                  <FiSmartphone />
                </div>
                <div>
                  <span className="smaller">Telefon:</span>
                  <br />
                  <a
                    href={`tel:+46${phone
                      .replace(/^0+/, '')
                      .replace(' ', '')
                      .replace('-', '')}`}
                  >
                    {phone}
                  </a>
                </div>
              </div>
            )}
            {email && (
              <div>
                <div className="icon-circular">
                  <FiMail />
                </div>
                <div>
                  <span className="smaller">E-post:</span>
                  <br />
                  <a href={`mailto:${email}`} className="darker">
                    {email}
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactCard
