import React from 'react'
import './mobileNavButton.scss'

function MobileNavButton({ expanded, handleToggleMenu, ariaControls }) {
  return (
    <button
        className="mobile-nav-button"
        aria-expanded={expanded}
        aria-controls={ariaControls}
        aria-label="Huvudmeny"
        onClick={handleToggleMenu}
      >
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </button>
  )
}

export default MobileNavButton;