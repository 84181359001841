import React, { useState, useEffect } from 'react'
import TextBody from '../textBody'
import './accordion.scss'
import { FiPlus } from 'react-icons/fi'

function AccordionItem({ heading, text }) {
  const [expanded, setExpanded] = useState(false)
  const id = '_' + Math.random().toString(36).substr(2, 9);

  function toggle(e) {
    setExpanded(!expanded)
  }

  useEffect(() => {

  })

  return (
    <div className="accordion" data-expanded={expanded}>
      <h2 className="accordion__header">
        <button
          className="reset"
          aria-expanded={expanded}
          aria-controls={id}
          onClick={toggle}
        >
          {heading}
          <FiPlus />
        </button>
      </h2>
      <div className="accordion__body" id={id}>
        <TextBody>{text}</TextBody>
      </div>
    </div>
  )
}

export default AccordionItem
