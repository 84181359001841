import { Link } from 'gatsby'
import React, { useState, useEffect, useLayoutEffect, useContext } from 'react'
// import { MdLanguage } from 'react-icons/md'
import MobileNavButton from '../mobileNavButton'
import { LayoutContext } from '../../layout/index.js'
import {
  disableFocusBehindModal,
  enableFocusBehindModal,
} from '../../util/modalHelper'
import './menu.scss'

function Menu({ items, additionalItems }) {

  const [menuExpanded, setMenuExpanded] = useState(false)
  const [menuCollapsing, setMenuCollapsing] = useState(false)
  const [isMobile, setIsMobile] = useState(false)
  const { localization, locale } = useContext(LayoutContext)

  useEffect(() => {
    const maxWidth = 1080
    matchMedia(`screen and (max-width:${maxWidth}px)`).addListener(function(
      mql
    ) {
      setIsMobile(mql.matches)
    })
  }, [])

  useLayoutEffect(() => {
    if (typeof window !== 'undefined' && window.innerWidth <= 1080) {
      setIsMobile(true)
    }
  }, [])

  function handleEsc(e) {
    if (e.keyCode === 27) {
      collapseMenu()
    }
  }

  function toggleMenu(e) {
    if (menuExpanded) {
      collapseMenu()
    } else {
      setMenuExpanded(true)
      if (document) {
        disableFocusBehindModal(document.getElementById('Menu'))
        document.addEventListener('keydown', handleEsc)
      }
    }
  }

  function collapseMenu() {
    setMenuCollapsing(true)
    setMenuExpanded(false)
    setTimeout(() => {
      setMenuCollapsing(false)
    }, 200)
    if (document) {
      document.removeEventListener('keydown', handleEsc)
      enableFocusBehindModal()
    }
  }

  const menuItems = items.map(item => {
    return (
      <MenuItem
        href={item.link}
        key={item.id}
        disabled={!menuExpanded && isMobile}
        className="menu__main__item"
        closeMenu={toggleMenu}
        isMobile={isMobile}
      >
        {item.pageName}
      </MenuItem>
    )
  })

  const additionalMenuItems = additionalItems.map(item => {
    return (
      <MenuItem
        href={item.link}
        key={item.id}
        disabled={!menuExpanded && isMobile}
        className="menu__additional__item"
        closeMenu={toggleMenu}
        isMobile={isMobile}
      >
        {item.pageName}
      </MenuItem>
    )
  })

  let opts = {}
  if (menuExpanded) {
    opts['className'] = 'menu menu--expanded'
    if (typeof document !== 'undefined')
      document.body.classList.add('menu-expanded')
  } else {
    opts['className'] = menuCollapsing ? 'menu menu--collapsing' : 'menu'
    opts['aria-hidden'] = isMobile
    if (typeof document !== 'undefined')
      document.body.classList.remove('menu-expanded')
  }

  return (
    <React.Fragment>
      <MobileNavButton
        expanded={menuExpanded}
        handleToggleMenu={toggleMenu}
        ariaControls="Menu"
      />
      <nav {...opts} id="Menu">
        <ul className="menu__main">{menuItems}</ul>
        <ul className="menu__additional">
          {additionalMenuItems}
          <MenuItem
            href={`/${locale === 'sv-SE' ? 'en' : ''}`}
            id="lang"
            disabled={!menuExpanded && isMobile}
            className="menu__additional__item"
            closeMenu={toggleMenu}
            isMobile={isMobile}
          >
            {localization.language}
          </MenuItem>
        </ul>
      </nav>
    </React.Fragment>
  )
}

function MenuItem({
  href,
  id,
  children,
  disabled,
  className,
  closeMenu,
  isMobile,
}) {
  let opts = {}
  if (disabled) {
    opts['tabIndex'] = '-1'
  }
  const isActive = ({ isPartiallyCurrent }) => {
    return isPartiallyCurrent ? { className: 'menu__main__item__active' } : null
  }
  return (
    <li key={id} className={className}>
      <Link
        {...opts}
        to={href}
        onClick={() => {
          if (isMobile) closeMenu()
        }}
        getProps={isActive}
      >
        {children}
      </Link>
    </li>
  )
}

export default Menu
