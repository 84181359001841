import React from 'react'
import ContactCard from '../../components/contactCard'

function Contacts({ contacts, title, showTitle }) {
  const html = contacts.map((contact, i) => {
    return (
      <ContactCard
        name={contact.name}
        title={contact.title}
        phone={contact.phone}
        email={contact.email}
        image={contact.image}
        key={i}
      />
    )
  })

  return (
    <div style={{ marginTop: '50px' }}>
      {showTitle && <h2 className="container__heading">{title}</h2>}
      {html}
    </div>
  )
}

export default Contacts
